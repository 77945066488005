<template>
  <div class="mt-10 flex flex-col w-full items-stretch">
        <slot></slot>
        <GlobalLoading />
    </div>
</template>

<script setup>
const authStore = useAuthStore();
onBeforeMount(async () => {
  const LIFF_ID = "2004940069-mw39pE16"
  let destinationUrl = window.location.href
  await authStore.lineSignIn(LIFF_ID, "");
});
</script>
